<template>
  <el-dialog
    custom-class="ql-dialog "
    :title="isEdit ? '编辑点阵笔' : '新增点阵笔'"
    :visible="dialogVisible"
    width="529px"
    @close="handleCancel"
  >
    <el-form v-if="dialogVisible" ref="form" :model="form" :rules="rules" label-width="auto">
      <el-form-item label="点阵笔编号" prop="dzbNo" size="small">
        <el-input
          :style="{ width: '100%' }"
          v-model.trim="form.dzbNo"
          placeholder="请输入"
          maxlength="20"
          @focus="handleFocus"
          @blur="handleBlur"
        />
      </el-form-item>

      <el-form-item label="点阵笔类型" prop="type" size="small">
        <el-select v-model="form.type" :style="{ width: '100%' }" @change="selectChange">
          <el-option v-for="item in types" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        v-if="[TYPE_STUDENT, TYPE_TEACHER].includes(form.type)"
        :label="nameLabel"
        class="is-required"
        size="small"
      >
        <student-select :defaultValue="studentSelectDefaultValue" :type="form.type" @select="handleStudentSelect" />
      </el-form-item>

      <el-form-item v-if="gradeVisible" label="所属年级" prop="gradeId" size="small">
        <el-select v-model="form.gradeId" :style="{ width: '100%' }">
          <el-option v-for="item in gradeList" :key="item.gradeId" :label="item.gradeName" :value="item.gradeId" />
        </el-select>
      </el-form-item>
    </el-form>

    <span slot="footer">
      <el-button size="small" @click="handleCancel">取消</el-button>
      <el-button size="small" type="primary" v-debounceClick="handleSave" :loading="loading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex';
// api
import { getWebSocketHost, savePen, getClassLessonGradeListByTeacher } from '@/api/equipment-manage';
// const
import { TYPE_STUDENT, TYPE_TEACHER } from '../constance';
// components
import { Form, FormItem, Dialog, Button, Input, Select, Option } from 'element-ui';
import StudentSelect from './select.vue';

export default {
  components: {
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    StudentSelect,
  },

  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    isEdit: {
      type: Boolean,
      required: true,
      default: false,
    },
    // 用于编辑 传参
    defaultValue: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  data() {
    return {
      // const
      TYPE_STUDENT,
      TYPE_TEACHER,

      loading: false,
      dialogVisible: false,

      socket: null,
      socketIP: '',
      socketTimeout: null,

      types: [
        {
          id: TYPE_STUDENT,
          name: '学生点阵笔',
        },
        {
          id: TYPE_TEACHER,
          name: '教师点阵笔',
        },
      ],

      gradeVisible: false,
      gradeList: [], // 年级列表 教师点阵笔

      form: {
        dzbNo: '18:11:71:',
        type: '',
        stuId: '', // 学生id/教师id - 学生点阵笔/教师点阵笔
        gradeId: '', // 年级 - 教师点阵笔
      },

      studentSelectDefaultValue: undefined,

      rules: {
        dzbNo: [
          { required: true, message: '请输入点阵笔编号', trigger: ['blur', 'change'] },
          {
            pattern: /^[A-Fa-f\d]{2}:[A-Fa-f\d]{2}:[A-Fa-f\d]{2}:[A-Fa-f\d]{2}:[A-Fa-f\d]{2}:[A-Fa-f\d]{2}$/,
            message: '请输入符合MAC格式的编号（仅支持0-9数字、A-F字母）',
            trigger: ['blur', 'change'],
          },
        ],
        type: [{ required: true, message: '请选择点阵笔类型', trigger: ['blur', 'change'] }],
        gradeId: [{ required: true, message: '请选择所属班级', trigger: ['blur', 'change'] }],
      },

      onSaving: false, // 是否正在保存
    };
  },

  computed: {
    ...mapState({
      userId: (state) => state.user.userInfo.userId,
    }),
    nameLabel() {
      if (this.form.type === TYPE_STUDENT) {
        return '学生姓名';
      }
      if (this.form.type === TYPE_TEACHER) {
        return '教师姓名';
      }
      return '';
    },
  },

  methods: {
    // handle 点阵笔编号 focus
    handleFocus() {
      const beginSocket = JSON.stringify({ key: 80001, userId: this.userId });
      this.socket?.send(beginSocket);
    },
    // handle 点阵笔编号 blur
    handleBlur() {
      const stopSocket = JSON.stringify({ key: 80003, userId: this.userId });
      this.socket?.send(stopSocket);
      this.checkDzbNo();
    },
    // 检查点阵笔编号，自动转成MAC地址
    checkDzbNo() {
      if (this.form.dzbNo) {
        let value = this.form.dzbNo;
        value = value.replace(/\s+/g, ''); // 去除空格
        value = value.replace(/[\u4e00-\u9fa5]/g, ''); // 去除中文
        value = value.replace(/：/g, ':'); // 中文冒号转成英文冒号
        this.form.dzbNo = value.replace(/\w(?=(\w{2})+$)/g, '$&:');
      }
    },
    // handle 选择点阵笔类型 change
    selectChange() {
      this.form.stuId = '';
      this.gradeVisible = false;
      if (this.studentSelectDefaultValue) this.studentSelectDefaultValue = undefined;
    },

    /**
     * @desc handle 搜索学生/教师
     * @param {string} id userId
     * @param {boolean} isInitalized 是否首次初始化
     */
    async handleStudentSelect(id, isInitalized = false) {
      this.form.stuId = id;

      if (!isInitalized) {
        this.form.gradeId = ''; // clear
      }

      await this.getClassLessonGradeListByTeacher();

      if (this.gradeList.length) {
        if (!isInitalized) {
          // 不是初始化（编辑）时，默认选中第一个
          this.form.gradeId = this.gradeList[0].gradeId;
        }
      } else {
        // gradeList.length为0，暂无任教
        this.gradeList = [
          {
            gradeId: null,
            gradeName: '暂无任教',
          },
        ];
        this.form.gradeId = null;
      }
      if (this.form.type === TYPE_TEACHER) {
        this.gradeVisible = true;
      }
      this.studentSelectDefaultValue = {
        id: '',
        name: '',
      };
    },

    // handle 确定
    handleSave() {
      if (this.onSaving) {
        return;
      }
      if (!this.studentSelectDefaultValue && this.form.type === TYPE_STUDENT) {
        this.$message.error('学生点阵笔学生姓名不能为空');
        return;
      }
      if (!this.studentSelectDefaultValue && this.form.type === TYPE_TEACHER) {
        this.$message.error('教师点阵笔教师姓名不能为空!');
        return;
      }
      if (this.form.type === TYPE_TEACHER && this.form.gradeId === null) {
        this.$message.error('该教师暂无任教，请先为该教师添加任教关系');
        return;
      }

      this.loading = true;

      this.$refs.form.validate((valid) => {
        if (valid) {
          let params = {
            pubDeviceDzbUserId: this.defaultValue?.pubDeviceDzbUserId,
            dzbNo: this.form.dzbNo,
            userType: this.form.type,
            userId: this.form.stuId,
            gradeId: this.form.gradeId,
          };

          this.onSaving = true;

          // api
          savePen(params)
            .then(() => {
              this.$message.success(params.pubDeviceDzbUserId ? `编辑成功` : '新增成功');
              this.$emit('success');
            })
            .catch(() => {
              this.loading = false;
            })
            .finally(() => {
              this.onSaving = false;
            });
        } else {
          this.loading = false;
          return false;
        }
      });
    },
    // handle cancel
    handleCancel() {
      this.$emit('update:visible', false);
    },
    // api 获取websocket地址
    getSocketIP() {
      const protocol = location.protocol == 'https:' ? 'wss' : 'ws';

      return getWebSocketHost().then((res) => {
        this.socketIP = `${protocol}://` + res.result + '/websocket';
      });
    },

    // api 获取教师任教班级列表
    getClassLessonGradeListByTeacher() {
      return getClassLessonGradeListByTeacher({
        teacherId: this.form.stuId,
        isAdd: this.isEdit ? false : true,
      }).then((res) => {
        this.gradeList = res.result;
      });
    },

    // util 开启websocket
    openWebScoket() {
      this.socket = new WebSocket(this.socketIP);

      this.socket.onopen = () => {
        const initSocket = JSON.stringify({ key: 40001, userId: this.userId, userType: 11 });
        this.socket.send(initSocket);
      };

      this.socketTimeout = setInterval(() => {
        const checkSocket = JSON.stringify({ key: 10001, clientType: 6 });
        this.socket?.send(checkSocket);
      }, 8000);

      this.socket.onclose = () => {
        clearInterval(this.socketTimeout);
        this.socketTimeout = null;
        this.socket = null;
      };

      //

      this.socket.onmessage = (res) => {
        const data = JSON.parse(res.data);
        if (res.data && data.key === 80002) {
          this.form.dzbNo = data.serialNumbers;
        }
      };
    },
  },

  watch: {
    visible: {
      handler: async function (val) {
        if (val) {
          // reset
          Object.assign(this.$data, this.$options.data());
          this.loading = false;
          this.$nextTick(() => {
            this.$refs.form?.clearValidate();

            if (this.defaultValue?.pubDeviceDzbUserId) {
              Object.assign(this.form, {
                dzbNo: this.defaultValue.dzbNo || '',
                type: String(this.defaultValue.userType) || '',
                stuId: this.defaultValue.stuId || '', // 学生id/教师id-学生点阵笔/教师点阵笔
                gradeId: this.defaultValue.gradeId || '',
              });

              this.studentSelectDefaultValue = {
                id: this.defaultValue?.userId || '',
                name: this.defaultValue?.userName || '',
              };
            }
          });
        }
        this.dialogVisible = val;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.tip {
  @apply text-12;
  line-height: 1.5;
  margin-top: 5px;
  color: #b9b9b9;
}
</style>
